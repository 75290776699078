<template>
    <router-view id="app" />
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/project">Project</router-link>
  </div> -->
</template>

<style>
#app {
  background-color: #2B2B2B;
  width: 100%
}

body {
  margin:0
}
/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
